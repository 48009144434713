.spot{
    position:absolute;
    top: 62px;
}

.dayclock { /* outside border circle */
    position: relative;
}

.koe {
    border-radius: 55%;
}

.dayclock::after { /* center circle */
    content: '';
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background-color: black;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    z-index: 11;
}

/*
.dayclock .dayclock-inner {  inside border circle
    border-radius: 50%;
    border: 2px solid black;
    position: absolute;
}
*/

.hour { /* hour numbers */
    --roation: 0;
    position: absolute;
    width: 20%;
    height: 88%;
    top: 6%;
    left: 40%;
    transform: rotate(var(--roation));
    /*border-radius: 1px;
    border-color: black;
    border: 1px solid gray;*/
}

.hourtext {
    position: relative;
    top: 2.5%;
    font-size: 0.625rem;
    font-family: "Courier 10 Pitch";
    text-align: center;
}

.hourtext2 {
    position: relative;
    top: 4%;
    font-size: 0.625rem;
    font-family: "Courier 10 Pitch";
    text-align: center;
}

.hour::before{ /* tick markers */
    content: '';
    position: absolute;
    top: -5.5%;
    left: 50%;
    transform: translateX(-50%);
    width: 2px;
    height: 7%;
    background-color: black;
    z-index: 0;
}

.price {
    --roation: 0;
    position: absolute;
    width: 20%;
    height: 92%;
    top: 4%;
    left: 40%;
    text-align: center;
    transform: rotate(var(--roation));
    font-size: 0.75rem;
    /*border-radius: 1px;
    border-color: black;
    border: 1px solid gray;*/
}

.price0 {--roation: 7.5deg}
.price1 {--roation: 22.5deg}
.price2 {--roation: 37.5deg}
.price3 {--roation: 52.5deg}
.price4 {--roation: 67.5deg}
.price5 {--roation: 82.5deg}
.price6 {--roation: 97.5deg}
.price7 {--roation: 112.5deg}
.price8 {--roation: 127.5deg}
.price9 {--roation: 142.5deg}
.price10 {--roation: 157.5deg}
.price11 {--roation: 172.5deg}
.price12 {--roation: 187.5deg}
.price13 {--roation: 202.5deg}
.price14 {--roation: 217.5deg}
.price15 {--roation: 232.5deg}
.price16 {--roation: 247.5deg}
.price17 {--roation: 262.5deg}
.price18 {--roation: 277.5deg}
.price19 {--roation: 292.5deg}
.price20 {--roation: 307.5deg}
.price21 {--roation: 322.5deg}
.price22 {--roation: 337.5deg}
.price23 {--roation: 352.5deg}

.hour1 {--roation: 15deg}
.hour2 {--roation: 30deg}
.hour3 {--roation: 45deg}
.hour4 {--roation: 60deg}
.hour5 {--roation: 75deg}
.hour6 {--roation: 90deg}
.hour7 {--roation: 105deg}
.hour8 {--roation: 120deg}
.hour9 {--roation: 135deg}
.hour10 {--roation: 150deg}
.hour11 {--roation: 165deg}
.hour12 {--roation: 180deg}
.hour13 {--roation: 195deg}
.hour14 {--roation: 210deg}
.hour15 {--roation: 225deg}
.hour16 {--roation: 240deg}
.hour17 {--roation: 255deg}
.hour18 {--roation: 270deg}
.hour19 {--roation: 285deg}
.hour20 {--roation: 300deg}
.hour21 {--roation: 315deg}
.hour22 {--roation: 330deg}
.hour23 {--roation: 345deg}
.hour24 {--roation: 360deg}

.hour1 div{
    transform: rotate(-15deg);
}

.hour2 div{
    transform: rotate(-30deg);
}

.hour3 div{
    transform: rotate(-45deg);
}

.hour4 div{
    transform: rotate(-60deg);
}

.hour5 div{
    transform: rotate(-75deg);
}

.hour6 div{
    transform: rotate(-90deg);
}

.hour7 div{
    transform: rotate(-105deg);
}

.hour8 div{
    transform: rotate(-120deg);
}

.hour9 div{
    transform: rotate(-135deg);
}

.hour10 div{
    transform: rotate(-150deg);
}

.hour11 div{
    transform: rotate(-165deg);
}

.hour12 div{
    transform: rotate(-180deg);
}

.hour13 div{
    transform: rotate(-195deg);
}

.hour14 div{
    transform: rotate(-210deg);
}

.hour15 div{
    transform: rotate(-225deg);
}

.hour16 div{
    transform: rotate(-240deg);
}

.hour17 div{
    transform: rotate(-255deg);
}

.hour18 div{
    transform: rotate(-270deg);
}

.hour19 div{
    transform: rotate(-285deg);
}

.hour20 div{
    transform: rotate(-300deg);
}

.hour21 div{
    transform: rotate(-315deg);
}

.hour22 div{
    transform: rotate(-330deg);
}

.hour23 div{
    transform: rotate(-345deg);
}

.dayhand {
    /* --roation:0; */
    position: absolute;
    bottom: 50%;
    left: 49%;
    border: 1px solid white;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    background-color: black;
    transform-origin: bottom;
    /* transform: translate(-50%) rotate(calc(var(--roation)* 1deg)); */
    z-index: 10;
}

.dayhand.secondhand {
    width: 5px;
    height: 45%;
    background-color: red;
}
.dayhand.minutehand {
    width: 7px;
    height: 32%;
    background-color: black;
}
.dayhand.hourhand {
    width: 9px;
    height: 30%;
    background-color: black;
}

.myspinner {
    position: absolute;
    top: 30%;
    opacity: 50%;
    z-index: 0;
}

.taxtext {
    position: absolute;
    z-index: 10;
    width: 40%;
    /*font-size: 0.625rem;*/
    font-size: 11px;
    text-align: center;
}

.pricering {
    position: absolute;
    bottom: 0%;
    left: 0%;
    z-index: 10;
    width: 100%;
    height: 100%;
}

.centring {
    position: absolute;
    bottom: -15%;
    left: -15%;
    z-index: 10;
    width: 130%;
    height: 130%;
    z-index: 0;
}

.navbar{
    z-index: 20;
}

.titletext {
    font-size: 1.0rem;
}

.selected-high {
    font-size: 12px;
}


.swiper-slide2 {
    text-align: center;
    font-size: 18px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.photos2 {
    align-items: left;
    width: 100%;
    height: 100%;
    margin-left: 10;
}